import React from 'react'
import Link from 'gatsby-link'
import Helmet from 'react-helmet'
import printerIcon from '../images/printer-icon.svg'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

function getParameterByName(name, url) {
  if (typeof (window) === "undefined") {
    return;
  }

  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const LanguageTitles = styled.div`

`

const LanguageTitle = styled.div`
  display: flex;
`

const TableCell = styled.td`
  padding: 5px 10px;
  vertical-align: middle;
  border: solid 1px #ccc;
`

// As for TableCell but with th and text-align: left.
// Could use polymorphism but I can't be bothered figuring out how.
const TableHeaderCell = styled.th`
  padding: 5px 10px;
  vertical-align: middle;
  border: solid 1px #ccc;
  text-align: left;
`

const Booklet = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title;

  const booklet = data.allContentfulBooklet.edges[0].node;

  const validBookletDownloads = booklet.bookletDownloads
    .filter(bd => bd.readFile != null || bd.printFile != null);

  const bookletLanguages = validBookletDownloads
    .reduce((a, b) => {
      return a.concat(b.languages);
    }, [])
    .sort((a, b) => a.ordinal - b.ordinal);

  const languagesFlattened = Array.from(new Set(bookletLanguages.map(l => l.title))).join(" | ");

  let bookletTitle = booklet.title;
  let subTitle = languagesFlattened;

  const selectedLanguage = getParameterByName("selectedLanguage");
  if (selectedLanguage != null) {
    const relevantBookletDownload = validBookletDownloads
      .filter(bd => bd.languages.some(l => l.title === selectedLanguage) && bd.translatedTitle != null)
      .sort((a, b) => a.languages.length - b.languages.length) // Get the one with the least number of languages
      .find(bd => true); // FirstOrDefault

    if (relevantBookletDownload != null) {
      bookletTitle = relevantBookletDownload.translatedTitle;
      subTitle = booklet.title;
    }
  }

  const languageRows = validBookletDownloads
    .sort((a, b) => {
      const combinedOrdinalGetter = f => Math.min.apply(null, f.languages.map(l => l.ordinal));
      return combinedOrdinalGetter(a) - combinedOrdinalGetter(b);
    })
    .map((bd, i) => {
      const languagesFlattened = bd.languages
        .sort((a, b) => a.ordinal - b.ordinal)
        .map((l, i) => {
          return <LanguageTitle key={i}>
            <span>{l.title}</span>
          </LanguageTitle>
        });

      const getFileRow = (f, type) => {
        if (f != null) {
          return <a href={f.file.url}>{type}</a>;
        }
        return <span>&nbsp;</span>;
      }

      return (
        <tr key={i}>
          <TableCell>
            <LanguageTitles>
              {languagesFlattened}
            </LanguageTitles>
          </TableCell>
          <TableCell>{getFileRow(bd.readFile, "Read")}</TableCell>
          <TableCell style={{ verticalAlign: 'middle' }}>{getFileRow(bd.printFile, "Print")}</TableCell>
        </tr>
      );
    });

  return (
    <Layout>
      <div className="post post-booklet">
        <Helmet title={`${bookletTitle} | ${siteTitle}`} />
        <h2 className="title">
          {bookletTitle}
          <span className="subtitle">{subTitle}</span>
        </h2>
        <div className="booklet-image-container">
          <div className="booklet-image" style={{ backgroundColor: booklet.imageBackgroundColour }}>
            <img src={booklet.image.file.url} alt={booklet.title} />
          </div>
        </div>
        <div className="entry">
          <div dangerouslySetInnerHTML={{ __html: booklet.blurb.childMarkdownRemark.html }}></div>
        </div>
        <div className="downloads" style={{ marginTop: '30px' }}>
          <Link to="/booklets/how-to-print" className="how-to-print"><img src={printerIcon} alt="Printer icon" />How to Print</Link>
          <h2>Download Booklets</h2>
          <table style={{ marginTop: '20px' }}>
            <thead>
              <tr>
                <TableHeaderCell>Language</TableHeaderCell>
                <TableHeaderCell colSpan="2">Downloads</TableHeaderCell>
              </tr>
            </thead>
            <tbody>
              {languageRows}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
}

export default Booklet

export const bookletSummaryFragment = graphql`
  fragment bookletSummary on ContentfulBooklet {
    id
    title
    ordinal
    image {
      file {
        url
      }
    }
    imageBackgroundColour
    bookletDownloads {
      ...bookletDownloadDetail
    }
    fields {
      path
    }
  }
`;

export const bookletDownloadFragment = graphql`
  fragment bookletDownloadDetail on ContentfulBookletDownload {
    id
    translatedTitle
    readFile {
      id
      file {
        url
      }
    }
    printFile {
      id
      file {
        url
      }
    }
    languages {
      ...languageSummary
    }
  }
`;

export const bookletDetailFragment = graphql`
  fragment bookletDetail on ContentfulBooklet {
    ...bookletSummary
    blurb {
      childMarkdownRemark {
        html
      }
    }
  }
`;

export const bookletQuery = graphql`
  query BookletQuery($id: String!) {
    site {
      ...siteMeta
    }
    allContentfulBooklet(filter: { id: { eq: $id } }) {
      edges {
        node {
          ...bookletDetail
        }
      }
    }
  }`
